<template>
  <p-toolbar dense class="it-edit-toolbar transparent elevation-0" :class="{ dark: false }">
    <toolbar-textarea :editor="editor"></toolbar-textarea>
  </p-toolbar>
</template>

<script>
import { PToolbar } from "plant-common"
import ToolbarTextarea from "../ToolbarTextarea"

export default {
  props: {
    editor: Object
  },
  components: {
    PToolbar,
    ToolbarTextarea
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style>
.ProseMirror img {
  margin-top: 0.75em;
  max-width: 50%;
  height: auto;
}

.ProseMirror img.ProseMirror-selectednode {
  outline: 3px solid #68cef8;
}

.it-edit-toolbar {
  height: 50px !important;
  z-index: 5;
}

.it-edit-toolbar > .v-toolbar__content {
  padding-left: 0;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  height: inherit !important;
}

.it-edit-toolbar.dark .v-toolbar__content {
  background-color: rgb(66, 66, 66);
  color: #fff;
}

.it-edit-toolbar.dark .v-btn__content {
  color: #fff;
}

.it-edit-toolbar.dark .v-btn {
  background-color: transparent !important;
}

.it-edit-toolbar-layout-selector {
  width: 160px;
  right: 20px;
}
</style>
