<template>
  <v-dialog :value="show" fullscreen>
    <v-card flat tile>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn flat icon @click="show = false"><v-icon>close</v-icon></v-btn>
      </v-card-title>

      <v-card-text class="card-text-height">
        <v-layout fill-height align-center>
          <v-img
            class="mx-auto"
            :src="src"
            contain
            :height="`${height}vh`"
            :max-height="`${maxHeight}vh`"
            :width="`${width}vh`"
            :max-width="`${maxWidth}vh`">
          </v-img>
        </v-layout>
      </v-card-text>
      <v-card-actions class="card-actions-height">
        <v-spacer></v-spacer>
        <v-slider
          :max="maxHeight"
          :min="minHeight"
          v-model="height"
          step="10"
          ticks="always"
          tick-size="2"
          prepend-icon="mdi-magnify-minus"
          @click:prepend="zoomBy(-zoomAmount)"
          append-icon="mdi-magnify-plus"
          @click:append="zoomBy(zoomAmount)"></v-slider>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FullScreenImage",
  props: {
    src: String
  },
  data() {
    return {
      show: true,
      maxHeight: 85,
      height: 65,
      minHeight: 25,
      maxWidth: 100,
      width: 80,
      minWidth: 40,
      zoomAmount: 10
    }
  },
  methods: {
    zoomBy(value) {
      const newValue = this.height + value
      if (newValue > this.maxHeight || newValue < this.minHeight) return
      this.height = newValue
      this.width = newValue + 15
    }
  }
}
</script>

<style scoped>
.card-text-height {
  height: 85vh;
}

.card-actions-height {
  height: 5vh;
}
</style>
