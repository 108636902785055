<template>
	<div
		:id="parentId"
		class="basic-block__animation-wrapper"
		:style="{
			...animationStyle,
			...transformEditModeStyle
		}"
	>
		<div class="basic-block" :style="style" style="position: relative">
			<template v-if="state === 'not-selected' && mode === 'edit'">
				<div @click="$parent.$emit('blockClick')" class="movable-available" style="z-index: 1000"></div>
			</template>
			<template v-else-if="state === 'primary-selected' && mode === 'edit'">
				<div
					@click="$parent.$emit('blockClick')"
					@dblclick="$parent.$emit('blockDblClick')"
					@mousedown="move"
					:class="{ movable: true, 'not-movable': value.protection.move }"
					style="z-index: 1000"
				></div>

				<div class="moveable-rotation" style="transform: translate(-50%) translate(125px, 0px) rotate(0rad)">
					<div class="moveable-line moveable-rotation-line" style="transform: scaleX(1)"></div>
					<div class="moveable-control moveable-rotation-control" style="transform: translate(0.5px) scale(1)"></div>
				</div>
				<div
					class="movable-control"
					:class="{ disabled: value.protection.resize }"
					data-rotate="center"
					:style="`transform: translate(${block.size.width / 2}px, ${block.size.height / 2}px)`"
				></div>
				<div
					class="rotable-control"
					:class="{ disabled: value.protection.resize }"
					data-rotate="control"
					:style="`
          transform: translate(${rotableX}px, ${rotableY}px) ${flipStyle}; 
          background-image: url(${require('./rotate.svg')})
        `"
					@mousedown="rotate"
				></div>
				<div
					class="movable-control"
					:class="{ disabled: value.protection.resize }"
					data-direction="n"
					:data-direction-cursor="handlerPositions[handlerPositionIndex + handlerFlipNSIndex]"
					:style="`transform: translate(${block.size.width / 2}px, 0px)`"
					@mousedown="resize"
				></div>
				<div
					class="movable-control"
					:class="{ disabled: value.protection.resize }"
					data-direction="ne"
					:data-direction-cursor="handlerPositions[handlerPositionIndex + 1 + handlerFlipIndex]"
					:style="`transform: translate(${block.size.width}px, 0px)`"
					@mousedown="resize"
				></div>
				<div
					data-direction="e"
					class="movable-control"
					:data-direction-cursor="handlerPositions[handlerPositionIndex + 2 + handlerFlipNSIndex]"
					:class="{ disabled: value.protection.resize }"
					:style="`transform: translate(${block.size.width}px, ${block.size.height / 2}px)`"
					@mousedown="resize"
				></div>
				<div
					class="movable-control"
					:class="{ disabled: value.protection.resize }"
					data-direction="se"
					@mousedown="resize"
					:data-direction-cursor="handlerPositions[handlerPositionIndex + 3 + handlerFlipIndex]"
					:style="`transform: translate(${block.size.width}px, ${block.size.height}px)`"
				></div>
				<div
					class="movable-control"
					:class="{ disabled: value.protection.resize }"
					data-direction="s"
					:data-direction-cursor="handlerPositions[handlerPositionIndex + 4 + handlerFlipNSIndex]"
					:style="`transform: translate(${block.size.width / 2}px, ${block.size.height}px)`"
					@mousedown="resize"
				></div>
				<div
					class="movable-control"
					:class="{ disabled: value.protection.resize }"
					data-direction="sw"
					:data-direction-cursor="handlerPositions[handlerPositionIndex + 5 + handlerFlipIndex]"
					:style="`transform: translate(0px, ${block.size.height}px)`"
					@mousedown="resize"
				></div>
				<div
					class="movable-control"
					:class="{ disabled: value.protection.resize }"
					data-direction="w"
					:data-direction-cursor="handlerPositions[handlerPositionIndex + 6 + handlerFlipNSIndex]"
					:style="`transform: translate(0px, ${block.size.height / 2}px)`"
					@mousedown="resize"
				></div>
				<div
					class="movable-control"
					:class="{ disabled: value.protection.resize }"
					data-direction="nw"
					:data-direction-cursor="handlerPositions[handlerPositionIndex + 7 + handlerFlipIndex]"
					@mousedown="resize"
					:style="`transform: translate(0px, 0px)`"
				></div>
			</template>
			<template v-else-if="mode !== 'edit'">
				<!-- Tooltip -->
				<transition name="button-tooltip">
					<div ref="tooltipText" v-if="showTooltip" :style="tooltipStyle">
						<span>{{ (actionHover || actionClick).text }}</span>
					</div>
				</transition>

				<!-- Open library popup -->
				<popup-button-action-library
					v-if="actionClick && actionClick.type == 'library'"
					v-model="showLibraryContent"
					:content_ref="actionClick.content_ref"
					:fullWidth="actionClick.fullWidth"
					:view="actionClick.view"
				/>
			</template>

			<!-- Block -->
			<div
				@click="handleClick"
				@mouseenter="handleMouseEnter"
				@mouseleave="handleMouseLeave"
				class="lp-basic-block-slot-wrapper"
				:class="`${state == 'edit-content' && !value.protection.data && mode === 'edit' ? 'edit-text' : ''} ${
					block.type
				}-${block.id}`"
				:id="block.id"
				:style="{
					width: '100%',
					height: '100%',
					cursor: actionClick && actionClick.type !== 'none' ? 'pointer' : 'default'
				}"
			>
				<slot name="main"></slot>
			</div>
		</div>
	</div>
</template>

<script>
import 'animate.css';
import '../../_css/animateOverwritten.css';
import PopupButtonActionLibrary from '../../button/BlockViewPopupLibrary.vue';
import PServiceContent from 'plant-common/src/services/PServiceContent';
import _ from 'lodash';

export default {
	name: 'BasicBlock',
	inject: ['blockController', 'blockEventBus'],
	components: { PopupButtonActionLibrary },
	props: {
		value: Object,
		mode: String,
		fullPage: Boolean
	},
	data() {
		return {
			animationStyle: {},
			inAnimation: this.value.animations[0],
			outAnimation: this.value.animations[1],
			showTooltip: false,
			showLibraryContent: false,
			lastPositionX: 0,
			lastPositionY: 0,
			handlerPositions: [
				'n',
				'ne',
				'e',
				'se',
				's',
				'sw',
				'w',
				'nw',
				'n',
				'ne',
				'e',
				'se',
				's',
				'sw',
				'w',
				'nw',
				'n',
				'ne',
				'e',
				'se',
				's',
				'sw',
				'w',
				'nw'
			],
			state: 'not-selected'
		};
	},
	async created() {
		if (this.value.data && this.value.data.content_ref && this.value.data.content_ref.$ref) {
			const ref = this.value.data.content_ref.$ref;
			const content = await PServiceContent.getContentFromRef(ref);
			this.value.data.content_ref = Object.assign(content, { $origin: ref });
		}

		this.setRotationBackwardCompatibility();

		this.setAnimationBackwardCompatibility();

		this.setAnimations();

		this.setBlockPositionToInt();

		this.setBlockStateListener();

		this.setBlockAction();
	},
	beforeDestroy() {
		if (this.blockPopup && !_.isEmpty(this.blockController)) {
			this.blockController.$off(`state-changed:${this.blockPopup.id}`);
		}
	},
	computed: {
		hasInAnimation() {
			return this.inAnimation && this.inAnimation.name != 'none';
		},
		hasOutAnimation() {
			return this.outAnimation && this.outAnimation.name != 'none';
		},
		parentId() {
			return `parent-${this.value.id}`;
		},
		tooltipStyle() {
			return {
				visibility: 'visible',
				position: 'absolute',
				background: '#616161',
				borderRadius: '2px',
				color: '#fff',
				fontSize: '28px',
				display: 'inline-block',
				padding: '5px 8px',
				width: 'fit-content',
				maxWidth: '500px',
				zIndex: '8',
				boxShadow: `
          0px 3px 1px -2px rgb(0 0 0 / 20%),
          0px 2px 2px 0px rgb(0 0 0 / 14%),
          0px 1px 5px 0px rgb(0 0 0 / 12%)
        `
			};
		},
		actionClick() {
			const clickAction = this.value.actions ? this.value.actions.find(action => action.trigger == 'click') : false;
			return clickAction || { type: 'none' };
		},
		actionHover() {
			const clickAction = this.value.actions ? this.value.actions.find(action => action.trigger == 'hover') : false;
			return clickAction;
		},
		handlerFlipNSIndex() {
			return this.block.flip.x != this.block.flip.y ? 4 : 0;
		},
		handlerFlipIndex() {
			return this.block.flip.x != this.block.flip.y ? 2 : 0;
		},
		handlerPositionIndex() {
			var angle = this.block.rotation > 0 ? this.block.rotation : this.block.rotation + 360;
			return Math.floor((angle + 22.5) / 45);
		},
		rotableX() {
			return this.block.size.width / 2;
		},
		rotableY() {
			if (this.block.flip.y) {
				return this.block.size.height + 60;
			}
			return -60;
		},
		flipStyle() {
			const scaleX = this.block.flip && this.block.flip.x ? 'scaleX(-1)' : '';
			const scaleY = this.block.flip && this.block.flip.y ? 'scaleY(-1)' : '';
			return `${scaleX} ${scaleY}`;
		},
		style() {
			const transform =
				this.mode === 'edit'
					? `rotate(${this.block.rotation}deg) ${this.flipStyle}`
					: `translate(${this.block.position.x}px, ${this.block.position.y}px) rotate(${this.block.rotation}deg) ${this.flipStyle}`;
			return {
				position: 'absolute',
				width: `${this.block.size.width}px`,
				height: `${this.block.size.height}px`,
				transform: `${transform}`
			};
		},
		transformEditModeStyle() {
			const transformStyle =
				this.mode === 'edit'
					? {
							position: 'absolute',
							width: `${this.block.size.width}px`,
							height: `${this.block.size.height}px`,
							transform: `translate(${this.block.position.x}px, ${this.block.position.y}px)`
						}
					: {
							position: 'absolute',
							width: '0px',
							height: '0px',
							transform: ''
						};
			return transformStyle;
		},
		block() {
			return this.$parent.value;
		},
		parent() {
			return this.$parent.$parent.parent;
		},
		gridSize() {
			return this.blockController.gridSize || [1, 1];
		},
		gridX() {
			return this.gridSize[0];
		},
		gridY() {
			return this.gridSize[1];
		},
		blockPopup() {
			return this.value.actions ? this.value.actions.find(action => action.type == 'popup') : undefined;
		},
		blockPopupBlocks() {
			return this.blockPopup ? this.blockPopup.blocks : [];
		},
		parentSubcanvasDelay() {
			return this.$parent.$attrs.subcanvasDelay ? this.$parent.$attrs.subcanvasDelay : 0;
		}
	},
	methods: {
		setVisibilityDelay(delay) {
			this.$nextTick(() => {
				const animationWrapper = document.getElementById(this.parentId);
				if (animationWrapper) animationWrapper.style.visibility = 'hidden';

				const block = document.getElementById(this.value.id);
				setTimeout(
					() => {
						if (block) {
							block.style.visibility = 'visible';
						} else {
							this.animationStyle = { visibility: 'visible' };
						}
					},
					(delay + this.parentSubcanvasDelay) * 1000
				);
			});
		},

		setRotationBackwardCompatibility() {
			if (!this.block.hasOwnProperty('rotation')) {
				this.$set(this.block, 'rotation', 0);
			}

			if (!this.block.hasOwnProperty('flip')) {
				this.$set(this.block, 'flip', {
					x: false,
					y: false
				});
			}
		},

		setAnimationBackwardCompatibility() {
			if (this.inAnimation && this.inAnimation.name == 'fadeOut') {
				this.outAnimation = JSON.parse(JSON.stringify(this.inAnimation));
				this.inAnimation.name = 'none';
				this.inAnimation.duration = 0;
				this.inAnimation.delay = 0;
			}

			if (this.inAnimation.name.includes('Big')) {
				this.inAnimation.name = this.inAnimation.name.replace(/Big/g, '').trim();
			}
		},

		setAnimations() {
			if (this.mode === 'edit') {
				this.animationStyle = { visibility: 'visible' };
			} else if (this.hasInAnimation) {
				this.setVisibilityDelay(parseInt(this.inAnimation.delay));
				this.writeAnimationStyle();
			} else if (this.hasOutAnimation) {
				this.setVisibilityDelay(0);
				this.writeAnimationStyle();
			} else {
				this.setVisibilityDelay(0);
			}
		},

		writeAnimationStyle() {
			this.animationStyle = {
				animation: this.value.animations.map(a => a.name).join(','),
				'animation-duration': this.value.animations.map(a => `${a.duration}s`).join(','),
				'animation-delay': this.value.animations.map(a => `${parseInt(a.delay) + this.parentSubcanvasDelay}s`).join(','),
				'animation-fill-mode': 'backwards, forwards'
			};
		},

		setBlockPositionToInt() {
			// For backwards compatibility for blocks that had the string position bug
			this.block.size.width = parseInt(this.block.size.width);
			this.block.size.height = parseInt(this.block.size.height);
			this.block.position.x = parseInt(this.block.position.x);
			this.block.position.y = parseInt(this.block.position.y);
		},

		setBlockAction() {
			// For backwards compatibility for blocks without actions property
			if (!this.block.hasOwnProperty('actions')) {
				this.$set(this.block, 'actions', []);
			}
		},

		setBlockStateListener() {
			// following line: Avoid to listen changes of Layouts
			if (!_.isEmpty(this.blockController)) {
				// Observe changes on block selection and change state accordingly
				this.blockController.$on(`state-changed:${this.value.id}`, state => {
					this.state = state;
					this.$emit('update:state', state);
				});
			}
		},

		async handleClick() {
			if (!this.actionClick || this.mode == 'edit') return;
			if (this.actionClick.content_ref && this.actionClick.content_ref.$ref) {
				const ref = this.actionClick.content_ref.$ref;
				const content = await PServiceContent.getContentFromRef(ref);
				this.actionClick.content_ref = Object.assign(content, { $origin: ref });
			}
			switch (this.actionClick.type) {
				case 'tooltip':
					this.showTooltip = !this.showTooltip;
					break;
				case 'link':
					if (this.actionClick.bookmark_target) {
						this.$parent.$emit('goToBookmarkTarget', this.actionClick.bookmark_target);
						this.$emit('goToBookmarkButton', this.actionClick.bookmark_target);
					}
					break;
				case 'externalLink':
					const sanitizeUrl = require('@braintree/sanitize-url').sanitizeUrl;
					const url = sanitizeUrl(this.actionClick.url);
					window.open(url, '_blank');
					break;
				case 'downloadAttachment':
					const attachment = this.actionClick.attachment;
					if (attachment.file) {
						const link = document.createElement('a');
						link.href = attachment.file.url;
						link.download = attachment.name;
						link.click();
					}
					break;
				case 'library':
					this.showLibraryContent = !this.showLibraryContent;
					break;
				case 'libraryNewTab':
					PServiceContent.openContent(
						this.value.actions.find(action => action.type === 'libraryNewTab').content_ref,
						'tab'
					);
					break;
				case 'popup':
					this.blockController.overlay = this.actionClick;
					break;
				case 'event':
					if (this.blockEventBus) this.blockEventBus.$emit(this.actionClick.event);
					break;
				case 'close':
					this.blockController.overlay = null;
					break;
			}
			// Set visited property for buttons if action is not link
			if (this.value.type === 'button' && this.actionClick.type !== 'link') {
				this.value.data.visited = this.value.data.disabled ? false : true;
			}
		},
		handleMouseEnter() {
			if (!this.actionHover) return;
			switch (this.actionHover.type) {
				case 'tooltip':
					this.showTooltip = true;
					break;
			}
		},
		handleMouseLeave() {
			if (!this.actionHover) return;
			switch (this.actionHover.type) {
				case 'tooltip':
					this.showTooltip = false;
					break;
			}
		},
		resize(evt) {
			if (!this.state == 'selected' || this.value.protection.resize) return;
			evt.preventDefault();
			evt.target.style.willChange = 'transform';
			var lastPositionX = evt.clientX;
			var lastPositionY = evt.clientY;
			var { top, bottom, left, right } = this.parent.getBoundingClientRect();
			var { direction } = evt.target.dataset;

			// helper function to limit coordinates inside parent
			var clamp = (x, a, b) => Math.max(a, Math.min(x, b));

			// Intermediate variables to store new calculated w, h, x, y before applying the grid
			var calculatedWidth = parseInt(this.block.size.width);
			var calculatedHeight = parseInt(this.block.size.height);
			var calculatedX = parseInt(this.block.position.x);
			var calculatedY = parseInt(this.block.position.y);

			// Precompute cosAngle and sinAngle since the angle will not change as block is being resized
			var angle = (this.block.rotation * Math.PI) / 180;
			var cosAngle = Math.cos(angle);
			var sinAngle = Math.sin(angle);
			const flipX = this.block.flip.x ? -1 : 1;
			const flipY = this.block.flip.y ? -1 : 1;

			// Invert direction if block is flipped
			if (flipX == -1) {
				if (direction.includes('e')) {
					direction = direction.replace('e', 'w');
				} else if (direction.includes('w')) {
					direction = direction.replace('w', 'e');
				}
			}
			if (flipY == -1) {
				if (direction.includes('s')) {
					direction = direction.replace('s', 'n');
				} else if (direction.includes('n')) {
					direction = direction.replace('n', 's');
				}
			}

			// Mouse Move Handler
			var mousemoveEventHandler = evt => {
				evt.preventDefault();
				var { clientX, clientY } = evt;
				var { scale } = this.blockController;

				// limit mouse poisitions to the parent div
				clientX = clamp(clientX, left, right);
				clientY = clamp(clientY, top, bottom);

				// delta variables for x, y,
				var [deltaX, deltaY] = [0, 0, 0, 0];
				deltaX = clientX - lastPositionX;
				deltaY = clientY - lastPositionY;
				var deltaXp = (deltaX * cosAngle + deltaY * sinAngle) / scale;
				var deltaYp = (-deltaX * sinAngle + deltaY * cosAngle) / scale;

				switch (direction) {
					case 'se':
						calculatedWidth += deltaXp;
						calculatedHeight += deltaYp;
						calculatedX += (deltaXp * (cosAngle - 1) - deltaYp * sinAngle) / 2;
						calculatedY += (deltaYp * (cosAngle - 1) + deltaXp * sinAngle) / 2;
						break;
					case 's':
						calculatedHeight += deltaYp;
						calculatedX += (-deltaYp * sinAngle) / 2;
						calculatedY += (deltaYp * (cosAngle - 1)) / 2;
						break;
					case 'e':
						calculatedWidth += deltaXp;
						calculatedX += (deltaXp * (cosAngle - 1)) / 2;
						calculatedY += (deltaXp * sinAngle) / 2;
						break;
					case 'n':
						calculatedHeight -= deltaYp;
						calculatedX += (-deltaYp * sinAngle) / 2;
						calculatedY += (deltaYp * (cosAngle + 1)) / 2;
						break;
					case 'ne':
						calculatedWidth += deltaXp;
						calculatedHeight += -deltaYp;
						calculatedX += (deltaXp * (cosAngle - 1) - deltaYp * sinAngle) / 2;
						calculatedY += (deltaXp * sinAngle + deltaYp * (cosAngle + 1)) / 2;

						break;
					case 'w':
						calculatedWidth += -deltaXp;
						calculatedX += (deltaXp * (cosAngle + 1)) / 2;
						calculatedY += (deltaXp * sinAngle) / 2;
						break;
					case 'sw':
						calculatedWidth += -deltaXp;
						calculatedHeight += deltaYp;
						calculatedX += (deltaXp * (cosAngle + 1) - deltaYp * sinAngle) / 2;
						calculatedY += (deltaXp * sinAngle + deltaYp * (cosAngle - 1)) / 2;
						break;
					case 'nw':
						calculatedWidth += -deltaXp;
						calculatedHeight += -deltaYp;
						calculatedX += (deltaXp * (cosAngle + 1) - deltaYp * sinAngle) / 2;
						calculatedY += (deltaXp * sinAngle + deltaYp * (cosAngle + 1)) / 2;
						break;
				}

				// round calculated values to the grid
				var { gridX, gridY } = this;
				this.block.position.x = Math.round(calculatedX / gridX) * gridX;
				this.block.position.y = Math.round(calculatedY / gridY) * gridY;

				var newWidth = Math.round(calculatedWidth / gridX) * gridX;
				var newHeight = Math.round(calculatedHeight / gridY) * gridY;
				var scaleWidth = newWidth / this.block.size.width;
				var scaleHeight = newHeight / this.block.size.height;
				this.block.size.width = newWidth;
				this.block.size.height = newHeight;

				if (this.block.data.blocks) {
					for (var block of this.block.data.blocks) {
						block.position.x *= scaleWidth;
						block.position.y *= scaleHeight;
						block.size.width *= scaleWidth;
						block.size.height *= scaleHeight;
					}
				}
				// store mouse positions for next move envent
				lastPositionX = clientX;
				lastPositionY = clientY;
			};
			window.addEventListener(
				'mouseup',
				evt => {
					evt.preventDefault();
					evt.target.style.willChange = 'auto';
					window.removeEventListener('mousemove', mousemoveEventHandler);
				},
				{ once: true }
			);
			window.addEventListener('mousemove', mousemoveEventHandler);
		},
		move(evt) {
			if (!this.state == 'selected' || this.value.protection.move) return;
			evt.preventDefault();
			evt.target.style.willChange = 'transform';
			evt.target.dataset.blockState = 'move';
			var lastPositionX = evt.clientX;
			var lastPositionY = evt.clientY;
			var lastBlockPositionX = this.block.position.x;
			var lastBlockPositionY = this.block.position.y;
			var { top, bottom, left, right } = this.parent.getBoundingClientRect();
			var stopMove = evt => {
				evt.preventDefault();
				evt.target.style.willChange = 'auto';
				evt.target.dataset.blockState = 'normal';
				window.removeEventListener('mousemove', mousemoveEventHandler);
			};
			var mousemoveEventHandler = evt => {
				evt.preventDefault();
				var { clientX, clientY } = evt;
				if (clientX < left || clientX > right || clientY < top || clientY > bottom) {
					stopMove(evt);
					return;
				}
				lastBlockPositionX += (clientX - lastPositionX) / this.blockController.scale;
				lastBlockPositionY += (clientY - lastPositionY) / this.blockController.scale;
				this.block.position.x = Math.round(lastBlockPositionX / this.gridX) * this.gridX;
				this.block.position.y = Math.round(lastBlockPositionY / this.gridY) * this.gridY;
				lastPositionX = clientX;
				lastPositionY = clientY;
			};
			window.addEventListener('mouseup', stopMove, { once: true });
			window.addEventListener('mousemove', mousemoveEventHandler);
		},
		rotate(evt) {
			if (!this.state == 'selected' || this.value.protection.move) return;
			evt.preventDefault();
			evt.target.style.willChange = 'transform';
			evt.target.dataset.blockState = 'move';
			var { top, width, left, height } = this.$el.getBoundingClientRect();
			var x0p = left + width / 2;
			var y0p = top + height / 2;
			var stopMove = evt => {
				evt.preventDefault();
				evt.target.style.willChange = 'auto';
				evt.target.dataset.blockState = 'normal';
				window.removeEventListener('mousemove', mousemoveEventHandler);
			};
			var mousemoveEventHandler = evt => {
				evt.preventDefault();
				var { clientX, clientY } = evt;
				var xp = clientX - x0p;
				var yp = clientY - y0p;
				this.block.rotation = Math.round((Math.atan2(yp, xp) * 180) / Math.PI + 90);
			};
			window.addEventListener('mouseup', stopMove, { once: true });
			window.addEventListener('mousemove', mousemoveEventHandler);
		}
	},
	watch: {
		// Listen to Slide Explorer state changes to "open popup when clicking in children block"
		'blockPopupBlocks.length': {
			immediate: true,
			deep: true,
			handler() {
				// if block has popup, listen to popup state-change
				if (this.blockPopup && !_.isEmpty(this.blockController)) {
					this.blockController.$on(`state-changed:${this.blockPopup.id}`, state => {
						if (state == 'primary-selected') {
							this.blockController.overlay = this.actionClick;
							this.blockController.overlay.parent = this.value.id;
							this.blockController.$emit('overlayActive', { type: this.actionClick.type, overlay: this.actionClick });
						}
					});
					// if popup have blocks, listen to each block state-change
					this.blockPopupBlocks.forEach(popupBlock => {
						this.blockController.$on(`state-changed:${popupBlock.id}`, state => {
							if (state == 'primary-selected') {
								this.blockController.overlay = this.actionClick;
								this.blockController.overlay.parent = this.value.id;
								this.blockController.$emit('overlayActive', { type: this.actionClick.type, overlay: this.actionClick });
							}
						});
					});
				}
			}
		},
		showTooltip: {
			immediate: false,
			handler() {
				this.$nextTick(() => {
					const action = this.actionHover || this.actionClick;
					if (this.$refs.tooltipText) {
						switch (action.position) {
							case 'bottom':
								this.$refs.tooltipText.style.bottom = `-${this.$refs.tooltipText.offsetHeight}px`;
								break;
							case 'left':
								this.$refs.tooltipText.style.left = `-${this.$refs.tooltipText.offsetWidth}px`;
								break;
							case 'right':
								this.$refs.tooltipText.style.right = `-${this.$refs.tooltipText.offsetWidth}px`;
								break;
							default:
								this.$refs.tooltipText.style.top = `-${this.$refs.tooltipText.offsetHeight}px`;
								break;
						}
					}
				});
			}
		}
	}
};
</script>

<style>
.button-tooltip-enter,
.button-tooltip-leave-to {
	visibility: hidden;
	margin: 0;
	padding: 0;
	opacity: 0;
}

.button-tooltip-enter-active,
.button-tooltip-leave-active {
	transition: all 0.5s;
}

.movable-available {
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: default;
}

.movable-available:hover {
	outline: solid 1px #4af;
	cursor: default;
}

.movable-available.disabled:hover {
	outline: solid 1px #ccc;
}

.edit-text {
	position: absolute;
	width: 100%;
	height: 100%;
	outline: dashed 2px gray;
	cursor: text;
}

.movable {
	position: absolute;
	width: 100%;
	height: 100%;
	outline: solid 1px #4af;
	background: rgba(255, 255, 255, 0);
	cursor: move;
}

.movable.disabled {
	outline: solid 1px #ccc;
	cursor: default;
}

.movable.not-movable {
	outline: solid 1px #ccc;
	cursor: default;
}

.movable.edit-disabled {
	cursor: not-allowed;
}

.movable:hover {
	outline-width: 2px;
}

.movable[data-block-state='move'] {
	cursor: move;
	outline-style: dashed;
}

.movable[data-block-state='move'] ~ .movable-control {
	display: none;
}

.movable.selected {
	outline: solid 1px #4af;
}

.movable-control {
	will-change: transform;
	position: absolute;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 2px solid #fff;
	box-sizing: border-box;
	background: #4af;
	z-index: 10;
	margin-top: -10px;
	margin-left: -10px;
}

.rotable-control {
	will-change: transform;
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-sizing: border-box;
	z-index: 10;
	margin-top: -20px;
	margin-left: -20px;
	cursor: grab;
	background-size: contain;
}

.movable-control:hover {
	background: orange;
}

.movable-control:active {
	background: red;
}

.movable-control[data-rotate] {
	cursor: all-scroll;
}

.movable-control[data-rotate='center'] {
	background: gray;
	outline: solid 1px white;
}

.movable-control[data-direction-cursor='n'] {
	cursor: ns-resize;
}

.movable-control[data-direction-cursor='s'] {
	cursor: ns-resize;
}

.movable-control[data-direction-cursor='e'] {
	cursor: ew-resize;
}

.movable-control[data-direction-cursor='w'] {
	cursor: ew-resize;
}

.movable-control[data-direction-cursor='se'] {
	cursor: nwse-resize;
}

.movable-control[data-direction-cursor='sw'] {
	cursor: nesw-resize;
}

.movable-control[data-direction-cursor='ne'] {
	cursor: nesw-resize;
}

.movable-control[data-direction-cursor='nw'] {
	cursor: nwse-resize;
}

.movable-control.disabled {
	background: #ccc;
	cursor: default;
}
</style>
