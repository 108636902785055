<template>
  <v-btn
    round
    class="elevation-0"
    outline
    @click="openNewContentDialog"
    color="success"
    e2e-id="new_content"
    v-if="!isInstructor && !isStudent">
    <v-icon left>mdi-plus</v-icon>New Content
  </v-btn>
</template>

<script>
import { PtModal } from 'plant-common';
import { mapActions, mapGetters, mapState } from "vuex"
import NewContentDialog from "./NewContentDialog.vue"

import {
  STORE_WORKFLOWS_NAME,
  ACTION_CREATE_ITEM,
  ACTION_CHANGE_WORKFLOW,
  ACTION_REASSIGN_USER_ROLE,
  ACTION_ITEM_REVISION_DRAFTS,
  ACTION_SELECT_ITEM_REVISION_DRAFT_ID,
  GETTER_SELECTED_ITEM_REVISION_DRAFT
} from "@/store_constants/workflows"

import { STORE_USER_NAME, GETTER_IS_INSTRUCTOR, GETTER_IS_STUDENT } from "@/store_constants/user"
import { getContentId, getType } from '../../../../utils/dbTool';
import NewContentFinished from "./NewContentFinished.vue"
import { STATE_PLANT_SETTINGS, STORE_LIBRARY_NAME } from "@/store_constants/library"
import { apps, getAppName } from '@/pages/commonApps';

export default {
  mixins: [PtModal],
  data() {
    return {
      type: undefined
    }
  },
  computed: {
    ...mapGetters(STORE_USER_NAME, {
      isInstructor: GETTER_IS_INSTRUCTOR,
      isStudent: GETTER_IS_STUDENT
    }),
    ...mapGetters(STORE_WORKFLOWS_NAME, {
      selectedItemRevisionDraft: GETTER_SELECTED_ITEM_REVISION_DRAFT
    }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    })
  },
  methods: {
    ...mapActions(STORE_WORKFLOWS_NAME, {
      createItem: ACTION_CREATE_ITEM,
      changeWorkflow: ACTION_CHANGE_WORKFLOW,
      reassignUserRole: ACTION_REASSIGN_USER_ROLE,
      getItemRevisionDrafts: ACTION_ITEM_REVISION_DRAFTS,
      selectItemRevisionDraftById: ACTION_SELECT_ITEM_REVISION_DRAFT_ID
    }),
    async openNewContentDialog() {
      var res = await this.$modal(NewContentDialog, { value: true })
      if (!res) return false
      var { title, custom_id, path_id, item_type, workflow } = res
      const content = await this.createItem({
        title,
        custom_id,
        path_id,
        item_type
      })
      if (content) {
        // We need to get all drafts and then select the new draft created to call setWorkflow method
        await this.getItemRevisionDrafts()
        await this.selectItemRevisionDraftById(getContentId(content.itemRevisionDraft))
        try {
          await this.setWorkflow(workflow)
          var res = await this.$modal(NewContentFinished, {
            value: true,
            success: true
          })
          if (!res) return false
          if (res == "new") {
            this.openNewContentDialog()
          } else if (res == "open") {
						this.openDraft(this.selectedItemRevisionDraft);
          }
        } catch (e) {
          var res = await this.$modal(NewContentFinished, {
            value: true,
            success: false
          })
          console.log(e)
        }
      }
    },
		openDraft(draft) {
			const contentType = getType(draft);
			const appName = getAppName(contentType);

			if (apps.includes(contentType)) {
				window.open(`/apps/${appName}/index.html?libraryId=${this.settings._id}&contentId=${getContentId(draft)}&revision=draft`);
			} else {
				window.open(`/content/${this.settings._id}/${getContentId(draft)}/draft`);
			}
		},
    async setWorkflow(workflow) {
      try {
        await this.changeWorkflow({ name: workflow.name })
        for (const contributor of workflow.contributors) {
          await this.reassignUserRole({
            user_id: contributor.user_id,
            role: contributor.role
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style></style>
