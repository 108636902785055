import {
  ACTION_DELETE_REFERENCE,
  ACTION_UPDATE_IS_EDITED,
  ACTION_UPLOAD_DRAFT,
  STATE_EDITING_ITEM_REVISION_DRAFT,
  STATE_ITEM_IS_EDITED,
  STATE_ITEM_REVISION,
  STATE_LOCKED_DRAFT,
  STORE_CONTENT_NAME,
  STORE_MODULE_EDITOR,
  MUTATION_SET_EDITING_ITEM_REVISION_DRAFT,
  MUTATION_LOCK_DRAFT,
  ACTION_SET_DRAFT,
  ACTION_GET_EDTITING_ITEM_REVISION_DRAFT,
  ACTION_SUBMIT_TRANSITION,
  ACTION_CREATE_DRAFT,
  ACTION_COPY_CONTENT_FROM_ITEM,
  ACTION_GUIDE_2_INTERACTIVE_PRESENTATION_TRANSITION
} from "@/store_constants/content"

import { NOTIFICATION_INFINITE_TIMEOUT } from "@/store_constants/notifications"
import PtApiClient from "plant-api-client"
import { getPureTreeData } from "he-tree-vue"
import { cleanObject, getContentId, getType, getLibraryIdFromURL, setClientLibraryId } from "@/utils/dbTool"
import moment from "moment"

export default {
  name: STORE_MODULE_EDITOR,
  namespaced: true,
  state: {
    [STATE_ITEM_IS_EDITED]: false,
    [STATE_EDITING_ITEM_REVISION_DRAFT]: false,
    [STATE_LOCKED_DRAFT]: false
  },
  mutations: {
    [MUTATION_SET_EDITING_ITEM_REVISION_DRAFT]({ state, rootState }, draft) {
      rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION] = draft
    },
    [MUTATION_LOCK_DRAFT](state, status) {
      state[STATE_LOCKED_DRAFT] = status
    }
  },
  actions: {
    [ACTION_DELETE_REFERENCE]({ state, rootState }, originValue) {
      const references = rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION].body.references

      rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION].body.references = references.filter(
        obj => obj.$origin !== originValue
      )
    },
    [ACTION_UPDATE_IS_EDITED]({ state, dispatch }) {
      state[STATE_ITEM_IS_EDITED] = true
    },
    async [ACTION_GET_EDTITING_ITEM_REVISION_DRAFT]({ commit, dispatch, rootState }, item_id) {
      try {
        var itemDraft = await PtApiClient.getDraftByContentId(item_id)
        dispatch(ACTION_SET_DRAFT, itemDraft)
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
            "notifications/error",
            { message: error.response.data.message },
            {
              root: true
            }
          )
        } else {
          dispatch(
            "notifications/error",
            { message: "Something happened when trying to fetch data" },
            {
              root: true
            }
          )
        }
      }
      return
    },
    [ACTION_SET_DRAFT]({ commit, dispatch, rootState }, itemDraft) {
      rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION] = itemDraft
    },
    async [ACTION_UPLOAD_DRAFT]({ commit, dispatch, state, rootState }) {
      commit(MUTATION_LOCK_DRAFT, true)
      let tempBody = rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION].body
      if (getType(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]) === "guide" && tempBody.contents.sections) {
        tempBody = JSON.parse(JSON.stringify(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION].body))
        tempBody.contents.sections = getPureTreeData(tempBody.contents.sections)
      }
      if (getType(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]) === "guide") {
        // Check if we have to delete comments (which location has been deleted)
        let commentsToDelete = []
        if (rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION].approval_process.comments) {
          rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION].approval_process.comments.forEach(comment => {
            if (comment.toDelete) {
              commentsToDelete.push(comment.location.id)
            }
          })
          if (commentsToDelete.length > 0) {
            await PtApiClient.deleteComments(
              getContentId(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]),
              commentsToDelete
            )
              .then(res => {
                if (res.isAxiosError) {
                  state[STATE_ITEM_IS_EDITED] = true
                  dispatch(
                    "notifications/error",
                    {
                      message: "Comments unlinked Not Deleted. An unknown error ocurred"
                    },
                    { root: true }
                  )
                } else {
                  rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION].approval_process.comments = rootState[
                    STORE_CONTENT_NAME
                  ][STATE_ITEM_REVISION].approval_process.comments.filter(el => !el.toDelete)
                }
              })
              .catch(e => {
                dispatch("notifications/error", { message: "Failed deleting unlinked comments" }, { root: true })
              })
          }
        }
      }
      if (getType(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]) === "lessonPlan") {
        // Check if we have to update slide_id in Guide to Interactive Presentation transition
        let commentsToUpdateInTransition = []
        if (rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION].approval_process.comments) {
          rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION].approval_process.comments.forEach(comment => {
            if (comment.toUpdateLocation) {
              commentsToUpdateInTransition.push(comment)
            }
          })
          if (commentsToUpdateInTransition.length > 0) {
            await PtApiClient.updateCommentsLocation(
              getContentId(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]),
              commentsToUpdateInTransition
            )
              .then(res => {
                if (res.isAxiosError) {
                  state[STATE_ITEM_IS_EDITED] = true
                  dispatch(
                    "notifications/error",
                    {
                      message: "Comments transition failed. An unknown error ocurred"
                    },
                    { root: true }
                  )
                }
              })
              .catch(e => {
                dispatch("notifications/error", { message: "Failed updating comments in transition" }, { root: true })
              })
          }
        }
      }
      dispatch(
        "notifications/info",
        {
          message: "Saving content, please wait..."
        },
        { root: true }
      )
      return PtApiClient.updateDraftBody(getContentId(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]), tempBody)
        .then(res => {
          if (res.isAxiosError) {
            state[STATE_ITEM_IS_EDITED] = true
            dispatch(
              "notifications/error",
              {
                message: `Item Not Saved. ${
                  res.response && res.response.data ? res.response.data.message : "An unknown error ocurred"
                }. Try it again, please.`
              },
              { root: true }
            )
          } else {
            return PtApiClient.updateDraftMetadata(
              getContentId(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]),
              cleanObject(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION].header.metadata)
            )
              .then(() => {
                state[STATE_ITEM_IS_EDITED] = false
                dispatch(
                  "notifications/info",
                  {
                    message: "Item Saved. Last saved at " + moment().format("LTS"),
                    timeout: NOTIFICATION_INFINITE_TIMEOUT
                  },
                  { root: true }
                )
                return res
              })
              .catch(e => {
                dispatch(
                  "notifications/warning",
                  {
                    message: "Item Saved but Failed saving Metadata",
                    timeout: NOTIFICATION_INFINITE_TIMEOUT
                  },
                  { root: true }
                )
                return e
              })
          }
          return res
        })
        .catch(e => {
          dispatch(
            "notifications/error",
            {
              message: "Failed saving Item",
              timeout: NOTIFICATION_INFINITE_TIMEOUT
            },
            { root: true }
          )
          return e
        })
        .finally(() => {
          commit(MUTATION_LOCK_DRAFT, false)
        })
    },
    async [ACTION_GUIDE_2_INTERACTIVE_PRESENTATION_TRANSITION]({ state, dispatch, rootState }, template) {
      try {
        var result = await PtApiClient.copyFiles(
          template.reference._id,
          rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]._id
        )
        return result
      } catch (e) {
        console.log(e)
        dispatch("notifications/error", { message: "Failed files copy" }, { root: true })
        return e
      }
    },
    async [ACTION_SUBMIT_TRANSITION]({ state, dispatch, rootState }, transition) {
      try {
        var itemDraft = await PtApiClient.updateDraftWorkflow(
          getContentId(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]),
          "transition",
          transition
        )
        dispatch("notifications/info", { message: "Updated workflow" }, { root: true })
        return itemDraft
      } catch (e) {
        console.log(e)
        dispatch("notifications/error", { message: "Failed transition" }, { root: true })
        return e
      }
    },
    async [ACTION_CREATE_DRAFT]({ state, commit, dispatch, rootState }, { itemRevision, libraryId }) {
      let itemDraft = false
      const ptApiClientLibraryId = getLibraryIdFromURL(PtApiClient.baseURL)
      if (libraryId && libraryId !== ptApiClientLibraryId) {
        setClientLibraryId(PtApiClient, libraryId)
      }

      // check if revision already has an assigned draft
      try {
        itemDraft = await PtApiClient.getDraftByContentId(getContentId(itemRevision, "no-dereference"))
      } finally {
        if (itemDraft) {
          setClientLibraryId(PtApiClient, ptApiClientLibraryId)
          return false
        }

        itemDraft = await PtApiClient.createDraft(getContentId(itemRevision), itemRevision._id)
        const changeWorkflowDraft = await PtApiClient.updateDraftWorkflow(
          getContentId(itemDraft),
          "method",
          "changeWorkflow",
          { name: "no_reviewer", lightweight: true }
        )
        const reassignDraft = await PtApiClient.updateDraftWorkflow(
          getContentId(changeWorkflowDraft),
          "method",
          "reassignRole",
          { role: "author", user_id: rootState.user.profile._id }
        )

        setClientLibraryId(PtApiClient, ptApiClientLibraryId)
        return reassignDraft
      }
    },
    async [ACTION_COPY_CONTENT_FROM_ITEM]({}, copyInfo) {
      try {
        let itemDraft = await PtApiClient.createDraftFromItem(
          copyInfo.draftId,
          copyInfo.custom_id,
          copyInfo.title,
          copyInfo.path_id,
          copyInfo.type
        )
        return itemDraft
      } catch (error) {
        return error
      }
    }
  }
}
