var render = function render(){var _vm=this,_c=_vm._self._c;return _c('p-content-container',[_c('p-toolbar',{style:(`margin-left:${_vm.isLeftPanelSelected ? 500 : 0}px; margin-right:${
      _vm.hasRightDrawer && _vm.rightDrawer ? 500 : 0
    }px;width:${
      _vm.isLeftPanelSelected
        ? _vm.hasRightDrawer && _vm.rightDrawer
          ? 'calc(100% - 1000px)'
          : 'calc(100% - 500px)'
        : _vm.hasRightDrawer && _vm.rightDrawer
        ? 'calc(100% - 500px)'
        : '100%'
    }`)},[_vm._t("toolbar"),_c('v-spacer'),(_vm.hasRightDrawer)?_c('p-toolbar-action',{attrs:{"e2e-id":"right-drawer","icon":"mdi-book-open","tooltip":"Open Properties drawer"},on:{"click":_vm.toggleRightDrawer}}):_vm._e()],2),_c('div',{staticClass:"full-view",style:(`margin-left:${_vm.isLeftPanelSelected ? 500 : 0}px; margin-right:${
      _vm.hasRightDrawer && _vm.rightDrawer ? 500 : 0
    }px;
          width:${
            _vm.isLeftPanelSelected
              ? _vm.hasRightDrawer && _vm.rightDrawer
                ? 'calc(100% - 1000px)'
                : 'calc(100% - 500px)'
              : _vm.hasRightDrawer && _vm.rightDrawer
              ? 'calc(100% - 500px)'
              : '100%'
          }`)},[_vm._t("main")],2),(_vm.hasRightDrawer)?_c('v-navigation-drawer',{attrs:{"value":_vm.rightDrawer,"disable-resize-watcher":"","right":"","absolute":"","width":"500px"}},[_vm._t("rightDrawerContents")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }