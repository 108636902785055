<template>
  <toolbar-button
    icon="mdi-file-plus-outline"
    :tooltip="tooltip ? tooltip : 'Create new content'"
    @click="newContentModal"></toolbar-button>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import { PtModal } from "plant-common"
import ToolbarButton from "@/pages/explorer/ExploreLibrary/components/Tools/ToolbarButton.vue"
import NewContentDialog from "@/pages/workflow/components/new-content/NewContentDialog.vue"
import workflows_store from "@/store/workflows"
import {
  STORE_WORKFLOWS_NAME,
  ACTION_CHANGE_WORKFLOW,
  ACTION_CREATE_ITEM,
  ACTION_ITEM_REVISION_DRAFTS,
  ACTION_REASSIGN_USER_ROLE,
  ACTION_SELECT_ITEM_REVISION_DRAFT_ID,
  GETTER_SELECTED_ITEM_REVISION_DRAFT
} from "@/store_constants/workflows"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import {
  STORE_NOTIFICATIONS_NAME,
  ACTION_NOTIFICATIONS_INFO,
  ACTION_NOTIFICATIONS_ERROR
} from "@/store_constants/notifications"
import { STORE_EXPLORER_NAME, STATE_FOLDER } from "@/store_constants/explorer"
import { getContentId } from "../../../../../utils/dbTool"

export default {
  mixins: [PtModal],
  props: ["tooltip"],
  components: { ToolbarButton },
  beforeCreate() {
    if (!this.$store.state[STORE_WORKFLOWS_NAME]) {
      this.$store.registerModule(STORE_WORKFLOWS_NAME, workflows_store)
    }
  },
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      folder: STATE_FOLDER
    }),
    ...mapGetters(STORE_WORKFLOWS_NAME, {
      selectedItemRevisionDraft: GETTER_SELECTED_ITEM_REVISION_DRAFT
    }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    })
  },
  methods: {
    ...mapActions(STORE_WORKFLOWS_NAME, {
      createItem: ACTION_CREATE_ITEM,
      getItemRevisionDrafts: ACTION_ITEM_REVISION_DRAFTS,
      selectItemRevisionDraftById: ACTION_SELECT_ITEM_REVISION_DRAFT_ID,
      changeWorkflow: ACTION_CHANGE_WORKFLOW,
      reassignUserRole: ACTION_REASSIGN_USER_ROLE
    }),
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      info: ACTION_NOTIFICATIONS_INFO,
      error: ACTION_NOTIFICATIONS_ERROR
    }),
    async newContentModal() {
      var res = await this.$modal(NewContentDialog, {
        value: true,
        path_set: this.folder
      })
      if (!res) return false
      var { title, custom_id, path_id, item_type, workflow } = res
      const content = await this.createItem({
        title,
        custom_id,
        path_id,
        item_type
      })
      if (content) {
        // We need to get all drafts and then select the new draft created to call setWorkflow method
        await this.getItemRevisionDrafts()
        await this.selectItemRevisionDraftById(getContentId(content.itemRevisionDraft))
        try {
          await this.setWorkflow(workflow)
          this.info({ message: "Content correctly created. Opening draft..." })
          window.open(`/content/${this.settings._id}/${getContentId(this.selectedItemRevisionDraft)}/draft`)
        } catch (e) {
          console.log(e)
          this.error({
            message: "Something happend when trying to create the new draft"
          })
        }
      }
    },
    async setWorkflow(workflow) {
      try {
        await this.changeWorkflow({ name: workflow.name })
        for (const contributor of workflow.contributors) {
          await this.reassignUserRole({
            user_id: contributor.user_id,
            role: contributor.role
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
