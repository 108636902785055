import { render, staticRenderFns } from "./BasicBlock.vue?vue&type=template&id=ebf33582"
import script from "./BasicBlock.vue?vue&type=script&lang=js"
export * from "./BasicBlock.vue?vue&type=script&lang=js"
import style0 from "./BasicBlock.vue?vue&type=style&index=0&id=ebf33582&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports