<template>
  <v-container style="height: 90vh; overflow: auto; padding: 10px">
    <template v-if="!contents.length == 0">
      <content-list :items="contents" @input="goToItem" :layout="'training'" defaultSort="name" />
    </template>
    <template v-else>
      <v-alert v-if="loading" :value="true" color="info" icon="mdi-information-outline">Loading data</v-alert>
      <div v-else :value="true" class="empty_folder">
        <v-img height="500" contain :src="require('@/assets/empty_folder.svg')"></v-img>
        <h5 class="text-center headline font-weight-light mt-5">No guides to show</h5>
      </div>
    </template>
  </v-container>
</template>
<script>
import store from "./store"
import { mapState, mapActions } from "vuex"
import { ContentList } from "plant-common"
import { STORE_LIBRARY_NAME, ACTION_SELECT_PLANT, STATE_PLANT_ID } from "@/store_constants/library"
import { STORE_USER_NAME, ACTION_SELECT_LIBRARY } from "@/store_constants/user"
import { getContentId, getMajorVersion, getMinorVersion, getType } from '../../utils/dbTool';

const { VUE_APP_IP_APP_AVAILABLE } = window.__env__ || false;
const IP_APP_AVAILABLE = VUE_APP_IP_APP_AVAILABLE === "true";

export default {
  props: {
    library_id: String
  },
  components: {
    ContentList
  },
  beforeCreate() {
    if (!this.$store.state.training) this.$store.registerModule("training", store)
  },
  beforeDestroy() {
    if (this.$store.state.training) this.$store.unregisterModule("training")
  },
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      plant_id: STATE_PLANT_ID
    }),
    ...mapState("training", ["contents", "loading"])
  },
  methods: {
    ...mapActions(STORE_LIBRARY_NAME, {
      selectPlant: ACTION_SELECT_PLANT
    }),
    ...mapActions("training", ["getAllGuidesAndIps"]),
    ...mapActions(STORE_USER_NAME, {
      selectLibrary: ACTION_SELECT_LIBRARY
    }),
		openIPApp(element) {
			const libraryId = this.plant_id
			const contentId = getContentId(element)
			let revision = `${getMajorVersion(element)}.${getMinorVersion(element)}`;

			const href = `/viewers/interactive-presentation/index.html?libraryId=${libraryId}&contentId=${contentId}&revision=${revision}`;
			window.open(href, "_blank");
		},
    goToItem(element) {
			if (this.loading) return;

			if (!element._id) return;

			if (IP_APP_AVAILABLE && getType(element) === "lessonPlan") {
				this.openIPApp(element);
				return;
			}

			let routeData = this.$router.resolve({
				name: "Content Viewer Latest",
				params: { id: getContentId(element), library_id: this.plant_id }
			})
			window.open(routeData.href, "_blank")
    }
  },
  watch: {
    library_id: {
      immediate: true,
      async handler() {
        const plant = await this.selectPlant(this.library_id)
        await this.selectLibrary()
        await this.getAllGuidesAndIps()
      }
    }
  }
}
</script>
<style scoped>
.empty_folder {
  margin: 50px auto;
}
</style>