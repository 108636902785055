import Vue from 'vue'
import './plugins/vuetify'
import './plugins/matomo'
import App from './App.vue'
import router from "./router"
import store from "./store/index"
import PortalVue from 'portal-vue'
import "@mdi/font/css/materialdesignicons.css"
import "@/services"

Vue.config.productionTip = false
Vue.use(PortalVue)
Vue.config.devtools = true

// To allow having the store in a modal and display notifications
Vue.prototype.$store = store;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')