import { Extensions } from 'plant-common';

export const apps = ['externalWebContent'];

export function getAppName(contentType) {
	const separator = ' ';
	const glue = '-';

	return Extensions[contentType].title.split(separator).join(glue).toLowerCase();
}
